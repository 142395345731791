import axios from "axios";

const baseUrl = "https://api.dev.liveaccess.net/";
// const baseUrl = "http://localhost:3001/";
// const baseUrl = "https://api.madarray.solutions/";

export default axios.create({
  baseURL: baseUrl,
  responseType: "json",
  //60 sec timeout
  timeout: 60000,

  //follow up to 10 HTTP 3xx redirects
  maxRedirects: 10,

  //cap the maximum content length we'll accept to 50MBs, just in case
  maxContentLength: 50 * 1000 * 1000,
});
