import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AppDispatch, RootState } from "../store/store";
import getAuthHeader from "../utils/getAuthHeader";
import { useOrganization } from "./organization.hook";
import axios from "axios";
import {
  setLoading,
  setSchedulerData,
} from "../store/reducers/scheduler.reducer";
import { useEvents } from "./event.hook";
import { setSuccess, setError } from "../store/reducers/notification.reducer";
export const useScheduler = () => {
  // const apiUrl = "https://scheduler-api.dev.liveaccess.net"
  // const apiUrl = "https://prod-scheduler-api.madarray.solutions";
  const apiUrl = "https://scheduler-api.dev.liveaccess.net"
  const dispatch: AppDispatch = useAppDispatch();
  const { handlers: eventsHandlers } = useEvents();
  const scheduler: any = useAppSelector(
    (state: RootState) => state.scheduler.scheduler
  );

  const loading: any = useAppSelector(
    (state: RootState) => state.scheduler.loading
  );

  const createCapture = async (
    eventId: string,
    shortUid: string,
    data: any
  ) => {
    dispatch(setLoading(true));
    const config = await getAuthHeader();
    try {
      await axios
        .post(apiUrl + "/capture", data, config)
        .then(async (res: any) => {
          const data = {
            schedulingId: res.data.id,
          };
          await eventsHandlers.eventUpdate(eventId, shortUid, data);
          dispatch(setLoading(false));
          dispatch(setSuccess("Stream Successfully Created"));
        })
        .catch((err) => {
          dispatch(setError("Something went wrong"));
          dispatch(setLoading(false));
        });
    } catch (e) {
      dispatch(setError("Something went wrong"));
      dispatch(setLoading(false));
      return e;
    }
  };
  const updateCapture = async (data: any) => {
    dispatch(setLoading(true));
    const config = await getAuthHeader();
    try {
      await axios
        .put(apiUrl + "/capture", data, config)
        .then(async (res: any) => {
          dispatch(setSuccess("Updated Successfully"));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setError("Something went wrong"));
          dispatch(setLoading(false));
        });
    } catch (e) {
      dispatch(setError("Something went wrong"));
      dispatch(setLoading(false));
      return e;
    }
  };

  const getCapture = async (schedulingId: string) => {
    dispatch(setLoading(true));
    const config = await getAuthHeader();
    try {
      await axios
        .get(apiUrl + "/capture/" + schedulingId, config)
        .then((res: any) => {
          dispatch(setSchedulerData(res.data));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          console.log("zxcerr", err);
          dispatch(setLoading(false));
        });
    } catch (e) {
      dispatch(setLoading(false));
      return e;
    }
  };

  const deleteCapture = async (
    eventId: string,
    shortUid: string,
    schedulingId: string
  ) => {
    dispatch(setLoading(true));
    const config = await getAuthHeader();
    try {
      await axios
        .delete(apiUrl + "/capture/" + schedulingId, config)
        .then(async (res: any) => {
          const data = {
            schedulingId: "",
          };
          await eventsHandlers.eventUpdate(eventId, shortUid, data);
          dispatch(setSuccess("Successfully Deleted"));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setError("Something went wrong"));
          dispatch(setLoading(false));
        });
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(setError("Something went wrong"));
      return e;
    }
  };
  const resetInformation = () => {
    dispatch(setSchedulerData({}));
  };

  const stopCapture = async (schedulingId: string) => {
    dispatch(setLoading(true));
    const config = await getAuthHeader();
    try {
      await axios
        .post(apiUrl + "/capture/stop/" + schedulingId, {}, config)
        .then(async (res: any) => {
          dispatch(setSuccess("Successfully Deleted"));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setError("Something went wrong"));
          dispatch(setLoading(false));
        });
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(setError("Something went wrong"));
      return e;
    }
  };
  const handlers = {
    getCapture,
    createCapture,
    updateCapture,
    deleteCapture,
    stopCapture,
    resetInformation,
  };
  return {
    handlers,
    loading,
    scheduler,
  };
};
